import React from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import "./ServiceCard.css"

type Props = {
  animation: string
  title: string
  content: string
  onDetailsButtonClick: Function
}

const ServiceCard: React.FC<Props> = ({
  animation,
  title,
  content,
  onDetailsButtonClick
}) => (
  <div className="service-card">
    <div className="left-align">
      <div className="animated-svg">
        <img
          src={animation}
          alt="services-animation"
          className="services-gif"
        />
      </div>
      <div className="service-description">
        <h5 className="title">{title}</h5>
        <p className="content">{content}</p>
      </div>
    </div>
    <div className="service-details-btn">
        <ButtonMain
          layout="secondary"
          hasIcon
          title="詳細へ"
          onClick={onDetailsButtonClick}
        />
    </div>
  </div>
)

export default ServiceCard
