import { Outlet, Route, Routes } from "react-router-dom"
import "./App.css"
import Error404 from "./pages/Errors/404"
import About from "./pages/About/About"
import Home from "./pages/Home/Home"
import useTracking from "./hooks/useTracking"
import { useRef, useState } from "react"
import { NavigationRefs } from "./models/NavigationRefs"
import { ServiceDetailsRefs } from "./models/ServiceDetailsRefs"
import NavigationBar from "./components/molecules/NavigationBar/NavigationBar"
import MobileMenu from "./components/organisms/MobileMenu/MobileMenu"
import { gaEventTrack } from "./utils/gaEventTrack"
import gaEvents from "./static/gaEvents.json"
import Footer from "./components/templates/Footer/Footer"
import Contact from "./pages/Contact/Contact"
import FAQ from "./pages/FAQ/FAQ"

function App() {
  useTracking()

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)

  const navigationRefs: NavigationRefs = {
    home: useRef(null),
    services: useRef(null),
    serviceDetails: useRef(null),
    aboutUs: useRef(null),
    whyUs: useRef(null),
    faq: useRef(null),
    contact: useRef(null),
    profile: useRef(null),
  }

  const serviceDetailsRefs: ServiceDetailsRefs = {
    scratchBuild: useRef(null),
    refactoring: useRef(null),
    prototyping: useRef(null),
    consulting: useRef(null),
  }

  const handleToggleMenu = () => {
    gaEventTrack(
      gaEvents.nav.categoryName,
      gaEvents.nav.actions.openMobileMenu,
      ""
    )
    setIsMenuOpened(!isMenuOpened)
  }

  const handleLinkClick = (
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section?: React.MutableRefObject<any>
  ) => {
    setIsMenuOpened(false)
    scrollTo(
      gaEvents.nav.categoryName,
      googleAnalyticsAction,
      googleAnalyticsLabel,
      section
    )
  }

  function scrollTo(
    googleAnalyticsCategory: string = gaEvents.home.categoryName,
    googleAnalyticsAction: string = gaEvents.home.actions.pushContact,
    googleAnalyticsLabel: string = "お問い合わせする",
    section: React.MutableRefObject<any> = navigationRefs.contact
  ) {
    gaEventTrack(
      googleAnalyticsCategory,
      googleAnalyticsAction,
      googleAnalyticsLabel
    )
    if (section !== null) {
      section.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  return (
    <div className="app">
      <Routes>
        <Route
          element={
            <>
              <NavigationBar
                isMenuOpened={isMenuOpened}
                navigationRefs={navigationRefs}
                onLinkClick={handleLinkClick}
                onMenuButtonClick={handleToggleMenu}
              />
              <MobileMenu
                isOpened={isMenuOpened}
                navigationRefs={navigationRefs}
                onLinkClick={handleLinkClick}
              />
              <Outlet />
              <Footer
                navigationRefs={navigationRefs}
                serviceRefs={serviceDetailsRefs}
                scrollTo={scrollTo}
              />
            </>
          }
        >
          <Route
            path="/"
            element={
              <Home
                navigationRefs={navigationRefs}
                serviceDetailsRefs={serviceDetailsRefs}
              />
            }
          ></Route>
          <Route
            path="/about"
            element={
              <About
                navigationRefs={navigationRefs}
                serviceDetailsRefs={serviceDetailsRefs}
                scrollTo={scrollTo}
              />
            }
          ></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
        </Route>
        <Route path="/*" element={<Error404 />}></Route>
      </Routes>
    </div>
  )
}

export default App
