import React, { Component } from "react"
import logo from "./logo.svg"

type Props = {
  size: string
}

export class Logo extends Component<Props> {
  static defaultProps = {
    size: "20px",
  }
  render(): React.ReactNode {
    return (
      <div className="logo">
        <img src={logo} alt={logo} style={{ height: this.props.size }}></img>
      </div>
    )
  }
}
