import React, { useState } from "react"
import { DoubleArrow } from "../../atoms/Icons/DoubleArrow/DoubleArrow"
import "./Collapsible.css"

export interface IProps {
  children: string
  title: string
}

const Collapsible: React.FC<IProps> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className="collapsible-menu">
      <button className="menu-title-area" onClick={handleOpen}>
        <p className="title font-bold tablet:text-p-small">{title}</p>
        <div className={`arrow-icon ${isOpen ? "rotated" : ""}`}>
          <DoubleArrow size="8px" color="white" />
        </div>
      </button>
      <div className={`menu-content-area ${isOpen ? "visible" : ""}`}>
        <p className="tablet:text-p-small">{children}</p>
      </div>
    </div>
  )
}

export default Collapsible
