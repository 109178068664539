import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import heroheader from "../../../assets/gifs/heroheader.gif"
import "./HeroHeader.css"
import { NavigationRefs } from "../../../models/NavigationRefs"

import gaEvents from "../../../static/gaEvents.json"

interface Props {
  navigationRefs: NavigationRefs
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

const HeroHeader: React.FC<Props> = ({ navigationRefs, scrollTo }) => {
  const handleContactButtonClick = () => {
    scrollTo(
      gaEvents.home.categoryName,
      gaEvents.home.actions.pushContact,
      "お問い合わせする",
      navigationRefs.contact
    )
  }

  return (
    <section className="hero-header">
      <div className="banner">
        <img
          src={heroheader}
          alt="Monotas Header Animation"
          className="hero-header-animation"
        />
        <div className="catchphrase">
          <h3 className="catchphrase-text tablet:text-h5">
            悩みこそ、チャンス
            <br />
            答えより、
            <span className="catchphrase-text yellow">コンサルテーション</span>
          </h3>
          <ButtonMain
            title="お問い合わせする"
            layout="decorated"
            isDarkTheme
            onClick={handleContactButtonClick}
          ></ButtonMain>
        </div>
        <img
          src={heroheader}
          alt="Monotas Header Animation"
          className="hero-header-animation"
        />
      </div>
    </section>
  )
}

export default HeroHeader
