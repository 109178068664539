import React, { MouseEventHandler } from "react"
import "./MobileMenuButton.css"

export interface IProps {
  isOpen: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const MobileMenuButton: React.FC<IProps> = ({ isOpen, onClick }) => {
  return (
    <button
      className={`mobile-menu-button ${isOpen ? "opened" : ""}`}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default MobileMenuButton
