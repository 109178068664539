import React from "react"
import Divider from "../../atoms/Divider/Divider"
import "./CaseStudyCard.css"

export interface CaseInfoItem {
  title: string
  content: string
}
export interface CaseInfo {
  number: number
  title: string
  content: string
  caseInfo: CaseInfoItem[]
}

type CardProps = {
  isDarkTheme: boolean
  caseStudy: CaseInfo
}

const CaseStudyCard: React.FC<CardProps> = ({ isDarkTheme, caseStudy }) => {
  const { number, title, content, caseInfo } = caseStudy

  return (
    <div
      className={`case-study-card ${
        isDarkTheme ? "case-study-dark" : "case-study-light"
      }`}
    >
      <div className="case-title">
        <h5 className="case-number tablet:text-p-large">Case {number}. </h5>
        <h5 className="title tablet:text-p-large">{title}</h5>
      </div>
      <Divider customBackground="bg-light-grey" />
      <div className="case-content">
        <p className="case-description tablet:text-p-small">{content}</p>
        <div className="case-info">
          {caseInfo.map(({ title, content }, index) => (
            <div key={index} className="criteria">
              <p className="info p-small font-bold">{`【${title}】`}</p>
              <p className="details p-small">{content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CaseStudyCard
