import React from "react"
import "./ServiceDetails.css"
import data from "../../../static/serviceDetails.json"
import ServiceDetail from "../../organisms/ServiceDetailCard/ServiceDetailCard"

interface Props {
  detailsRef: React.MutableRefObject<any>
  cardRef: (index: number) => React.MutableRefObject<any>
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

const ServiceDetails: React.FC<Props> = ({ detailsRef, cardRef, scrollTo }) => {
  return (
    <section
      id="details-and-results"
      className="service-details"
      ref={detailsRef}
    >
      {data.map(
        (
          {
            anchorLink,
            serviceNameEN,
            serviceNameJP,
            animationUrl,
            title,
            description,
            caseStudy,
          }: any,
          index
        ) => {
          return (
            <ServiceDetail
              key={index}
              cardRef={cardRef(index)}
              anchorLink={anchorLink}
              serviceNameEN={serviceNameEN}
              serviceNameJP={serviceNameJP}
              animationUrl={animationUrl}
              title={title}
              description={description}
              caseStudy={caseStudy}
              isDarkTheme={index % 2 !== 0}
              number={`0${index + 1}`}
              onContactButtonClick={scrollTo}
            ></ServiceDetail>
          )
        }
      )}
    </section>
  )
}

export default ServiceDetails
