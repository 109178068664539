import React from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import banner from "./banner.png"
import "./SectionBanner.css"

type Props = {
  bannerTitle?: string
  animation?: string
  title: string
  content?: string
  onButtonClick: Function
  buttonTitle: string
}
export const SectionBanner: React.FC<Props> = ({
  bannerTitle,
  animation,
  title,
  content,
  onButtonClick,
  buttonTitle,
}) => {
  return (
    <div className="section-banner">
      <div className="banner-background">
        <p className="title-en p-small">{bannerTitle}</p>
        <img className="background-img" src={banner} alt="vision-banner"></img>
      </div>
      <div className="banner-content responsive-grid">
        <div className="animated-svg grid-span-1">
          <img src={animation} alt="consulting-cubic" />
        </div>
        <div className="text-content grid-span-2">
          <h3 className="title tablet:text-h5">{title}</h3>
          <p className="description p-small">{content}</p>
        </div>
        <div className="vision-details-button grid-span-1-end">
          <ButtonMain
            layout="decorated"
            isDarkTheme
            title={buttonTitle}
            customBackground="bg-charcoal-wrapper"
            onClick={onButtonClick}
          />
        </div>
      </div>
    </div>
  )
}
