import React, { ReactElement } from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import "./SectionHeading.css"

export interface Heading {
  isDark?: boolean
  titleEN?: string
  animationUrl?: string
  subtitle?: string
  titleJP?: string
  customTitleEl?: ReactElement
  description?: string
  isDescriptionBold?: boolean
  buttonTitle?: string
  onButtonClick?: Function
}

const SectionHeading: React.FC<Heading> = ({
  isDark = false,
  titleEN,
  animationUrl,
  subtitle,
  titleJP,
  customTitleEl,
  description,
  isDescriptionBold = false,
  buttonTitle,
  onButtonClick,
}) => {
  const englishTitleEl = <p className="section-name">{titleEN}</p>

  const animationEl = animationUrl ? (
    <div className="animated-svg">
      <img
        src={animationUrl}
        alt="Monotas Service Animation"
        className="service-animation"
      />
    </div>
  ) : null

  const japaneseTitleEl = customTitleEl ? (
    customTitleEl
  ) : titleJP ? (
    <h3 className="heading-title tablet:text-h5">{titleJP}</h3>
  ) : (
    <div></div>
  )

  return (
    <div
      className={`section-heading responsive-grid ${isDark ? "dark" : "light"}`}
    >
      <div className="left-section grid-span-1">
        {titleEN ? englishTitleEl : animationEl}
      </div>
      <div className="right-section grid-span-3">
        {subtitle && (
          <p className="heading-subtitle tablet:text-caption">{subtitle}</p>
        )}
        {japaneseTitleEl}
        {description && (
          <p className={`heading-description tablet:text-p-small ${isDescriptionBold ? "font-bold" : ""}`}>
            {description}
          </p>
        )}
        {buttonTitle && (
          <ButtonMain
            layout="decorated"
            isDarkTheme={isDark}
            title={buttonTitle}
            onClick={onButtonClick}
          />
        )}
      </div>
    </div>
  )
}

export default SectionHeading
