import React from "react"
import Divider from "../../atoms/Divider/Divider"
import SectionHeading from "../../molecules/SectionHeading/SectionHeading"
import "./Profile.css"

interface Props {
  profileRef: React.MutableRefObject<any>
}

const Profile: React.FC<Props> = ({ profileRef }) => {
  const data = [
    { title: "会社名", content: "株式会社モノタス" },
    {
      title: "英文社名",
      content: "MONOTAS INC.",
    },
    {
      title: "所在地",
      content: "〒110-0005 \n 東京都台東区　上野3-14-4　原沢ビル3A",
    },
    {
      title: "代表取締役",
      content: "富居 聡",
    },
    {
      title: "創立",
      content: "2015年10月1日",
    },
    {
      title: "事業内容",
      content:
        "システムインテグレーション \n\n システムコンサルティング \n\n Webサービス開発 \n\n UI/UXデザイン \n\nクラウド利用支援",
    },
  ]
  return (
    <section id="company-profile" className="company-profile" ref={profileRef}>
      <SectionHeading isDark titleEN="COMPANY PROFILE" titleJP="会社概要" />
      <div className="responsive-grid">
        <div className="grid-span-2">
          {data.map(({ title, content }, index) => {
            return (
              <div className="profile-item" key={index}>
                <div className="flex flex-wrap">
                  <p className="profile-title text-orange-s100 font-bold">
                    {title}
                  </p>
                  <p className="profile-content mb-6">{content}</p>
                </div>
                <Divider customBackground="bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Profile
