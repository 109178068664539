import { useState, useEffect } from "react"

export default function useScroll() {
  const [isScrolling, setIsScrolling] = useState<boolean>(false)

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = window.scrollY

      if (scrolled > 300) {
        setIsScrolling(true)
      } else if (scrolled <= 300) {
        setIsScrolling(false)
      }
    }

    window.addEventListener("scroll", toggleVisible)
    return () => window.removeEventListener("scroll", toggleVisible)
  }, [])

  return isScrolling
}
