import React, { useEffect, useRef, useState } from "react"
import { DownArrow } from "../Icons/DownArrow/DownArrow"
import "./InputDropdown.css"

export type SelectOption = {
  id: number
  value: string
}

export type Props = {
  label: string
  placeholder: string
  value: string
  isRequired?: boolean
  optionProp: Array<SelectOption>
  onChange: (selectedValue: string) => void
}

export const InputDropdown: React.FC<Props> = (props: Props) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)

  function onSelect(title: string) {
    props.onChange(title)
  }

  const inputRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const handler = (e: any) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsListOpen(false)
      }
    }

    window.addEventListener("click", handler)
    return () => {
      window.removeEventListener("click", handler)
    }
  })

  return (
    <>
      <div className="input-wrapper">
        <div className="label-wrapper flex">
          <p className="input-label p-small font-bold">{props.label}</p>
          {props.isRequired === true ? (
            <p className="required p-small">必須</p>
          ) : null}
        </div>

        <div className="dropdown-input border border-black h-full rounded-md">
          <button
            ref={inputRef}
            type="button"
            onClick={() => setIsListOpen((prev) => !prev)}
            className="select"
          >
            {props.value ? (
              <p className="p-small">{props.value}</p>
            ) : (
              <p className="p-small text-medium-grey">{props.placeholder}</p>
            )}

            <div className="dropdown-arrow absolute">
              <DownArrow size="10px" />
            </div>
          </button>
        </div>

        {isListOpen && (
          <ul className="select-ul">
            {props.optionProp.map((item: { id: number; value: string }) => (
              <li
                key={item.id}
                onClick={() => onSelect(item.value)}
                onKeyDown={() => onSelect(item.value)}
                className="select-li"
              >
                <p className="p-small">{item.value}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
