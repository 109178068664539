import ContactForm from "../../organisms/ContactForm/ContactForm"
import "./ContactUs.css"

interface Props {
  contactRef: React.MutableRefObject<any>
}

const ContactUs: React.FC<Props> = ({ contactRef }) => {
  return (
    <section id="contact" className="contact-us" ref={contactRef}>
      <ContactForm />
    </section>
  )
}

export default ContactUs
