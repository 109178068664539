import { SectionBanner } from "../../organisms/SectionBanner/SectionBanner"
import animation from "./consulting.gif"
import "./Vision.css"

interface Props {
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

const Vision: React.FC<Props> = ({ scrollTo }) => {
  const title = "上野から世界へ \n Ueno to the World"
  const content =
    "文化芸術の街「上野」で一番クリエイティブな集団となり、そして上野から世界に出ます。ものづくりを通して、幸福に満ちた世の中を創造し、誰もが自分の重要性を感じられる空間をつくります。"

  return (
    <section id="vision" className="vision">
      <SectionBanner
        bannerTitle="OUR VISION"
        animation={animation}
        content={content}
        title={title}
        buttonTitle="詳しく見る"
        onButtonClick={scrollTo}
      />
    </section>
  )
}

export default Vision
