import React from "react"
import { useNavigate } from "react-router-dom"
import { NavigationRefs } from "../../../models/NavigationRefs"
import { ServiceDetailsRefs } from "../../../models/ServiceDetailsRefs"
import { gaEventTrack } from "../../../utils/gaEventTrack"
import { Logo } from "../../atoms/Logo/Logo"
import "../Footer/Footer.css"

import gaEvents from "../../../static/gaEvents.json"

interface Props {
  navigationRefs: NavigationRefs
  serviceRefs: ServiceDetailsRefs
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

const Footer: React.FC<Props> = ({ navigationRefs, serviceRefs, scrollTo }) => {
  const navigate = useNavigate()

  const handleLogoClick = () => {
    gaEventTrack(
      gaEvents.footer.categoryName,
      gaEvents.footer.actions.pushHome,
      ""
    )
    navigate("/")
  }

  return (
    <section id="footer" className="bg-black">
      <div className="footer">
        <button className="tablet:hidden" onClick={handleLogoClick}>
          <Logo size="45px" />
        </button>
        <button className="hidden tablet:block" onClick={handleLogoClick}>
          <Logo size="67px" />
        </button>
        <div className="footer-links">
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushScratchBuild,
                "スクラッチビルド",
                serviceRefs.scratchBuild
              )
            }
          >
            <a href="#scratch-build">
              <p>スクラッチビルド</p>
            </a>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushRefactoring,
                "リファクタリング",
                serviceRefs.refactoring
              )
            }
          >
            <p>リファクタリング</p>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushPrototyping,
                "プロトタイピング",
                serviceRefs.prototyping
              )
            }
          >
            <p>プロトタイピング</p>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushConsulting,
                "コンサルティング",
                serviceRefs.consulting
              )
            }
          >
            <p>コンサルティング</p>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushWhyUs,
                "なぜMONOTAS?",
                navigationRefs.whyUs
              )
            }
          >
            <p>
              なぜ<span className="font-brand">MONOTAS？</span>
            </p>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushFaq,
                "よくある質問",
                navigationRefs.faq
              )
            }
          >
            <p>よくある質問</p>
          </button>
          <button
            className="footer-link"
            onClick={() =>
              scrollTo(
                gaEvents.footer.categoryName,
                gaEvents.footer.actions.pushCompanyProfile,
                "会社概要",
                navigationRefs.profile
              )
            }
          >
            <p>会社概要</p>
          </button>
        </div>
        <p className="version-number p-small">©2022 MONOTAS Inc.</p>
      </div>
    </section>
  )
}

export default Footer
