import React, { MutableRefObject } from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import heroheader from "../../../assets/gifs/heroheader.gif"
import "./MobileMenu.css"
import {} from "../../../App"
import { NavigationRefs } from "../../../models/NavigationRefs"

import gaEvents from "../../../static/gaEvents.json"
import { Link, useNavigate } from "react-router-dom"

interface IProps {
  isOpened: boolean
  navigationRefs: NavigationRefs
  onLinkClick: (
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section?: React.MutableRefObject<any>
  ) => void
}

const MobileMenu: React.FC<IProps> = ({
  isOpened,
  navigationRefs,
  onLinkClick,
}) => {
  const navigate = useNavigate()
  const currentPath = window.location.pathname
  const onClickContact = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/contact")
    onLinkClick(gaEvents.nav.actions.pushContact, "相談してみる")
  }

  const homeNav = (
    <div className="navigation-links">
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushService,
            "サービス",
            navigationRefs.services
          )
        }
      >
        <p className="p-large font-bold">サービス</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushServiceDetails,
            "実績例",
            navigationRefs.serviceDetails
          )
        }
      >
        <p className="p-large font-bold">実績例</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushWhyUs,
            "なぜMONOTAS?",
            navigationRefs.whyUs
          )
        }
      >
        <p className="p-large font-bold">
          なぜ<span className="font-brand font-normal">MONOTAS？</span>
        </p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushFaq,
            "よくある質問",
            navigationRefs.faq
          )
        }
      >
        <p className="p-large font-bold">よくある質問</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushCompanyProfile,
            "会社概要",
            navigationRefs.profile
          )
        }
      >
        <p className="p-large font-bold">会社概要</p>
      </button>
    </div>
  )

  const onClickHome = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/")
    onLinkClick(gaEvents.nav.actions.pushHome, "ホーム")
  }

  const onClickAbout = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/about")
    onLinkClick(gaEvents.nav.actions.pushAboutUs, "MONOTASについて")
  }

  const onClickFAQ = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/faq")
    onLinkClick(gaEvents.nav.actions.pushAboutUs, "よくある質問")
  }

  const pageNav = (
    <div className="navigation-links">
      <button className="navigation-link text-p" onClick={onClickHome}>
        <p className="p-large font-bold">ホーム</p>
      </button>

      <button className="navigation-link text-p" onClick={onClickAbout}>
        <p className="p-large font-bold">
          <span className="font-brand font-normal">MONOTAS</span>について
        </p>
      </button>

      {/* TODO：ページできたらリンクを変更する */}
      <Link to="/">
        <button
          className="navigation-link text-p"
          onClick={() =>
            onLinkClick(gaEvents.nav.actions.pushAchievements, "実績例")
          }
        >
          <p className="p-large font-bold">実績例</p>
        </button>
      </Link>

      <button className="navigation-link text-p" onClick={onClickFAQ}>
        <p className="p-large font-bold">よくある質問</p>
      </button>
    </div>
  )

  return (
    <div className={`mobile-menu ${isOpened ? "opened" : ""}`}>
      <div className="mobile-menu-content">
        {currentPath === "/" ? homeNav : pageNav}
        <ButtonMain
          layout="primary"
          title="相談してみる"
          hasIcon
          onClick={() => {
            currentPath === "/"
              ? onLinkClick(
                  gaEvents.nav.actions.pushContact,
                  "相談してみる",
                  navigationRefs.contact
                )
              : onClickContact()
          }}
        />
      </div>
      <img
        src={heroheader}
        alt="Monotas Home Page Animation"
        className="hero-header-animation mobile:hidden"
      />
    </div>
  )
}

export default MobileMenu
