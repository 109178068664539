import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

const useTracking = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize("G-MT3K0GX8BG")
    ReactGA.set({ page: location.pathname })
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    })
  }, [location])
}

export default useTracking
