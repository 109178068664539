import "./WhyUs.css"
import Divider from "../../atoms/Divider/Divider"
import TableItem from "../../molecules/TableItem/TableItem"
import SectionHeading from "../../molecules/SectionHeading/SectionHeading"
import React from "react"

interface Props {
  whyUsRef: React.MutableRefObject<any>
}

const WhyMonotas: React.FC<Props> = ({ whyUsRef }) => {
  return (
    <section id="why-us" className="why-us" ref={whyUsRef}>
      <SectionHeading
        isDark
        titleEN="Why Us?"
        customTitleEl={
          <h3 className="title tablet:text-h5">
            なぜ<span className="monotas-font font-normal">MONOTAS？</span>
          </h3>
        }
        description="システム会社はたくさんあるし、どこにお願いすれば良いのかわからない！という方へ"
        isDescriptionBold
      />
      <div className="why-us-table">
        <Divider />
        <TableItem
          title="Our process"
          content="ドメインモデリングによりお客様の「課題の本質」を見出し、解決方法を可視化させます。"
        ></TableItem>
        <TableItem
          title="Our communication"
          content="スクラムという開発手法を前提にコミュニケーションを円滑に行い、お客様の潜在的な時間と金銭的コストを最小限に抑えつつ、100以上のプロジェクトで効果的かつ効率的に成功を収めてきました。"
        ></TableItem>
        <TableItem
          title="Our staff"
          content="システムの大小、業種を問わず、様々なシステム開発に携わっております。企画設計から開発運用までプロジェクトにおける全工程に対応するだけではなく、保守・バージョンアップなどトータルなサポートを提供します。"
        ></TableItem>
      </div>
    </section>
  )
}

export default WhyMonotas;