import React from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import Divider from "../../atoms/Divider/Divider"
import CaseStudyCard, {
  CaseInfo,
} from "../../molecules/CaseStudyCard/CaseStudyCard"
import SectionHeading from "../../molecules/SectionHeading/SectionHeading"
import "./ServiceDetailCard.css"

export interface Service {
  cardRef: React.MutableRefObject<any>
  isDarkTheme: boolean
  anchorLink: string
  number: string
  serviceNameEN: string
  serviceNameJP: string
  animationUrl: string
  title: string
  description: string
  caseStudy: CaseInfo
  onContactButtonClick: Function
}

const ServiceDetailCard: React.FC<Service> = ({
  cardRef,
  isDarkTheme,
  anchorLink,
  number,
  serviceNameEN,
  serviceNameJP,
  animationUrl,
  title,
  description,
  caseStudy,
  onContactButtonClick,
}) => {
  return (
    <div
      id={anchorLink}
      className={`service-detail-card ${isDarkTheme ? "dark" : "light"}`}
      ref={cardRef}
    >
      <div className="details-title">
        <p className="details-number">{number}</p>
        <p className="service-title tablet:text-p-small">{serviceNameEN}</p>
      </div>
      <Divider isDarkTheme={!isDarkTheme} />
      <div className="details-content-wrapper">
        <div className="px-16">
          <SectionHeading
            isDark={isDarkTheme}
            animationUrl={animationUrl}
            subtitle={serviceNameJP}
            titleJP={title}
            description={description}
          />
        </div>
        <div className="case-studies responsive-grid">
          <div className="left-section grid-span-1">
            <div className="title p-small">Case Studies</div>
            <div className="see-all-button">
              <a href="#contact">
                <ButtonMain
                  layout="decorated"
                  isDarkTheme={isDarkTheme}
                  title="お問い合わせする"
                  onClick={onContactButtonClick}
                />
              </a>
            </div>
          </div>
          <div className="right-section grid-span-3">
            <CaseStudyCard isDarkTheme={isDarkTheme} caseStudy={caseStudy} />
            <div className="see-all-button hidden mt-40 tablet:block">
              <a href="#contact">
                <ButtonMain
                  layout="decorated"
                  isDarkTheme={isDarkTheme}
                  title="お問い合わせする"
                  onClick={onContactButtonClick}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetailCard
