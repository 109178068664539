import { useState } from "react"
import "./InputField.css"

type EmailInput = {
  layout: "email"
  label: string
  placeholder: string
  isRequired?: boolean
  value: string
  handleChange: Function
}

type TextInput = {
  layout: "text-area"
  id: string
  label: string
  placeholder: string
  isRequired?: boolean
  value: string
  handleChange: Function
}

type DefaultInput = {
  layout: "default"
  id: string
  label: string
  placeholder: string
  isRequired?: boolean
  value: string
  handleChange: Function
}

type Input = EmailInput | TextInput | DefaultInput

export function InputField(props: Input) {
  const [isDisabled] = useState<boolean>(false)
  const [error] = useState<string>("")

  const handleChange = (event: any) => {
    props.handleChange(event.target.id, event.target.value)
  }

  const inputEmailContent =
    props.layout === "email" ? (
      <div className="input-email relative">
        <input
          id="email"
          type="email"
          disabled={isDisabled}
          className="input"
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
        ></input>
      </div>
    ) : (
      <input
        id={props.id}
        type="text"
        className="input"
        disabled={isDisabled}
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleChange}
      ></input>
    )
  return (
    <div className="input-wrapper">
      <div className="flex">
        <p className="input-label p-small">{props.label}</p>
        {props.isRequired === true ? (
          <p className="required p-small">必須</p>
        ) : null}
      </div>
      {props.layout === "text-area" ? (
        <textarea
          id={props.id}
          className="text-input"
          disabled={isDisabled}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
        ></textarea>
      ) : (
        inputEmailContent
      )}
      {error ? <p className="error-message">{error}</p> : null}
    </div>
  )
}
