import "./Home.css"
import HeroHeader from "../../components/templates/HeroHeader/HeroHeader"
import ServicesOutline from "../../components/templates/ServicesOutline/ServicesOutline"
import WhyMonotas from "../../components/templates/WhyUs/WhyUs"
import Vision from "../../components/templates/Vision/Vision"
import ServiceDetails from "../../components/templates/ServiceDetails/ServiceDetails"
import FAQ from "../../components/templates/FAQ/FAQ"
import ContactUs from "../../components/templates/ContactUs/ContactUs"
import ScrollButton from "../../components/atoms/ScrollButton/ScrollButton"
import Profile from "../../components/templates/Profile/Profile"
import { NavigationRefs } from "../../models/NavigationRefs"
import { ServiceDetailsRefs } from "../../models/ServiceDetailsRefs"
import gaEvents from "../../static/gaEvents.json"
import { gaEventTrack } from "../../utils/gaEventTrack"
import { useNavigate } from "react-router-dom"

interface Props {
  navigationRefs: NavigationRefs
  serviceDetailsRefs: ServiceDetailsRefs
}
function Home(props: Props) {
  const detailRef = (index: number) => {
    switch (index) {
      case 0:
        return props.serviceDetailsRefs.scratchBuild
      case 1:
        return props.serviceDetailsRefs.refactoring
      case 2:
        return props.serviceDetailsRefs.prototyping
      case 3:
        return props.serviceDetailsRefs.consulting
      default:
        return props.serviceDetailsRefs.scratchBuild
    }
  }

  function scrollTo(
    googleAnalyticsCategory: string = gaEvents.home.categoryName,
    googleAnalyticsAction: string = gaEvents.home.actions.pushContact,
    googleAnalyticsLabel: string = "お問い合わせする",
    section: React.MutableRefObject<any> = props.navigationRefs.contact
  ) {
    gaEventTrack(
      googleAnalyticsCategory,
      googleAnalyticsAction,
      googleAnalyticsLabel
    )
    section.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  const navigate = useNavigate()
  const useScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    })
  }

  const handleVisionButtonClick = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollToTop()
    navigate("/about")
  }

  return (
    <div className="home">
      <HeroHeader navigationRefs={props.navigationRefs} scrollTo={scrollTo} />
      <ServicesOutline
        servicesRef={props.navigationRefs.services}
        cardRef={detailRef}
        scrollTo={scrollTo}
      />
      <ServiceDetails
        detailsRef={props.navigationRefs.serviceDetails}
        cardRef={detailRef}
        scrollTo={scrollTo}
      />
      <WhyMonotas whyUsRef={props.navigationRefs.whyUs} />
      <Vision scrollTo={handleVisionButtonClick} />
      <FAQ
        faqRef={props.navigationRefs.faq}
        scrollTo={() =>
          scrollTo(
            gaEvents.home.categoryName,
            gaEvents.home.actions.pushContact,
            "解決しない場合 お問い合わせへ",
            props.navigationRefs.contact
          )
        }
      />
      <ContactUs contactRef={props.navigationRefs.contact} />
      <Profile profileRef={props.navigationRefs.profile} />
      <ScrollButton />
    </div>
  )
}

export default Home
