import React, { MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import useScroll from "../../../hooks/useScroll"
import { NavigationRefs } from "../../../models/NavigationRefs"
import { gaEventTrack } from "../../../utils/gaEventTrack"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import { Logo } from "../../atoms/Logo/Logo"
import MobileMenuButton from "../../atoms/MobileMenuButton/MobileMenuButton"
import "./NavigationBar.css"
import gaEvents from "../../../static/gaEvents.json"

export interface IProps {
  isMenuOpened: boolean
  navigationRefs: NavigationRefs
  onLinkClick: (
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section?: React.MutableRefObject<any>
  ) => void
  onMenuButtonClick: MouseEventHandler<HTMLButtonElement>
}

const NavigationBar: React.FC<IProps> = ({
  isMenuOpened,
  navigationRefs,
  onLinkClick,
  onMenuButtonClick,
}) => {
  const isScrolling = useScroll()
  const navigate = useNavigate()

  const handleLogoClick = () => {
    gaEventTrack(gaEvents.nav.categoryName, gaEvents.nav.actions.pushHome, "")
    navigate("/")
  }

  const currentPath = window.location.pathname
  const homeNav = (
    <div className="navigation-links">
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushService,
            "サービス",
            navigationRefs.services
          )
        }
      >
        <p className="p-small">サービス</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushServiceDetails,
            "実績例",
            navigationRefs.serviceDetails
          )
        }
      >
        <p className="p-small">実績例</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushWhyUs,
            "なぜMONOTAS?",
            navigationRefs.whyUs
          )
        }
      >
        <p className="p-small">
          なぜ<span className="font-brand font-normal">MONOTAS？</span>
        </p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushFaq,
            "よくある質問",
            navigationRefs.faq
          )
        }
      >
        <p className="p-small">よくある質問</p>
      </button>
      <button
        className="navigation-link text-p"
        onClick={() =>
          onLinkClick(
            gaEvents.nav.actions.pushCompanyProfile,
            "会社概要",
            navigationRefs.profile
          )
        }
      >
        <p className="p-small">会社概要</p>
      </button>
    </div>
  )

  const onClickHome = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/")
    onLinkClick(gaEvents.nav.actions.pushHome, "ホーム")
  }

  const onClickContact = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/contact")
    onLinkClick(gaEvents.nav.actions.pushContact, "相談してみる")
  }

  const onClickAbout = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/about")
    onLinkClick(gaEvents.nav.actions.pushAboutUs, "MONOTASについて")
  }

  const onClickFAQ = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/faq")
    onLinkClick(gaEvents.nav.actions.pushAboutUs, "よくある質問")
  }

  const pageNav = (
    <div className="navigation-links">
      <button className="navigation-link text-p" onClick={onClickHome}>
        <p className="p-small">ホーム</p>
      </button>
      <button className="navigation-link text-p" onClick={onClickAbout}>
        <p className="p-small">
          <span className="font-brand font-normal">MONOTAS</span>について
        </p>
      </button>

      {/* TODO：ページできたらリンクを変更する */}
      {/* <Link to="/">
        <button
          className="navigation-link text-p"
          onClick={() =>
            onLinkClick(gaEvents.nav.actions.pushAchievements, "実績例")
          }
        >
          <p className="p-small">実績例</p>
        </button>
      </Link> */}

      <button className="navigation-link text-p" onClick={onClickFAQ}>
        <p className="p-small">よくある質問</p>
      </button>
    </div>
  )

  return (
    <nav className={`navbar relative ${isScrolling ? "scrolled" : ""}`}>
      <div className="header">
        <button onClick={handleLogoClick}>
          <Logo size="50px" />
        </button>
        {currentPath === "/" ? homeNav : pageNav}
        <ButtonMain
          layout="primary"
          title="相談してみる"
          hasIcon
          onClick={() => {
            currentPath === "/"
              ? onLinkClick(
                  gaEvents.nav.actions.pushContact,
                  "相談してみる",
                  navigationRefs.contact
                )
              : onClickContact()
          }}
        />
        <div className="mm hidden tablet:block">
          <MobileMenuButton isOpen={isMenuOpened} onClick={onMenuButtonClick} />
        </div>
      </div>
    </nav>
  )
}

export default NavigationBar
