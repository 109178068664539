import React from "react"
import banner from "./big-banner.jpeg"
import { NavigationRefs } from "../../models/NavigationRefs"
import ScrollButton from "../../components/atoms/ScrollButton/ScrollButton"
import "./About.css"
import { ServiceDetailsRefs } from "../../models/ServiceDetailsRefs"
import SectionHeading from "../../components/molecules/SectionHeading/SectionHeading"
import Divider from "../../components/atoms/Divider/Divider"
import TableItem from "../../components/molecules/TableItem/TableItem"
import { SectionBanner } from "../../components/organisms/SectionBanner/SectionBanner"
import { useNavigate } from "react-router-dom"

interface Props {
  navigationRefs: NavigationRefs
  serviceDetailsRefs: ServiceDetailsRefs
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

function About(props: Props) {
  const navigate = useNavigate()

  const onClickContact = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/contact")
  }

  const titleAboutJP = "人が人らしく、\n 自分が自分らしく"
  const descriptionAbout =
    "ただ作る、ただ便利にするのではなく、「人が人らしく、自分が自分らしく」生きられる世の中にするため、我々は「意味がある」ものづくりを最重要視します。\n\n そして、これまで先人が得た知識をベースに、ものづくりを通して世の中に貢献します。"
  const titleVision = "上野から世界へ \n Ueno to the World"
  const descriptionVision =
    "文化芸術の街「上野」で一番クリエイティブな集団となり、そして上野から世界に出る。\n\n 唯一無二のものづくりを行う集団になること。\n\n ものづくりを通して、幸福に満ちた世の中を創造し、誰もが自分の重要性を感じられる空間をつくること。"

  return (
    <div className="about">
      <div className="about-banner">
        <img className="background-img" src={banner} alt="about-banner"></img>
      </div>
      <div className="about-section-heading">
        <SectionHeading
          isDark
          titleEN="ABOUT US"
          titleJP={titleAboutJP}
          description={descriptionAbout}
        ></SectionHeading>
      </div>
      <div className="catchphrase-section-heading tablet:p-[24px]">
        <SectionHeading
          animationUrl="./assets/gif/ServiceGif/scratchBuilding.gif"
          description="これまでのモノ（知恵や知識）を「組み合わせ」て、新しいモノ・価値づくりを行っていきます。"
          customTitleEl={
            <div className="name-section-heading">
              <h3 className="name-title tablet:text-h5 monotas-font font-normal">
                mono-tas
              </h3>
              <p className="name-description">
                自分たちらしい、「唯一」のモノづくりを行っていきます。
              </p>
              <h3 className="name-title">モノ - 足す</h3>
            </div>
          }
        ></SectionHeading>
      </div>
      <div className="value-section-heading">
        <SectionHeading
          isDark
          titleEN="OUR VALUES"
          customTitleEl={
            <h3 className="title tablet:text-h5">
              <span className="monotas-font font-normal">MONOTAS</span>
              の経営方針
            </h3>
          }
        ></SectionHeading>
        <div className="direction-table">
          <Divider />
          <TableItem
            isJpTitle
            title="課題の本質"
            customContent={
              <div>
                <b>何を解決しようとしているのか?</b>
                <br />
                <br />
                課題・要望に忠実に従うだけでは本当の解決にならない場合があります。
                <br />
                <br />
                便利にするだけではなく、意味を作ることに重きを置くこと。
              </div>
            }
          ></TableItem>
          <TableItem
            isJpTitle
            title="モノタスが考える価値"
            customContent={
              <div>
                モノタスが考える価値とは、<b>課題を解決すること</b>にあります。
                <br />
                <br />
                私たちは、価値を提供することに重きを置き、実践していきます。
              </div>
            }
          ></TableItem>
          <TableItem
            isJpTitle
            title="自立した有機的な組織づくり"
            customContent={
              <div>
                課題の本質・価値をしっかりとらえたうえで、
                <b>「自分はどうしたいのか」を考え行動すること</b>
                、またその行動を支えられる組織づくりを進めます。
              </div>
            }
          ></TableItem>
        </div>
      </div>
      <div className="vision-section-heading">
        <SectionHeading
          isDark
          titleEN="OUR VISION"
          titleJP={titleVision}
          description={descriptionVision}
        ></SectionHeading>
      </div>
      <div className="contact-section-heading">
        <SectionBanner
          bannerTitle="CONTACT US"
          animation="./assets/gif/ServiceGif/consulting.gif"
          title="私たちと新しいサービスを生み出しませんか？"
          buttonTitle="お問合せする"
          onButtonClick={onClickContact}
        ></SectionBanner>
      </div>
      <ScrollButton />
    </div>
  )
}
export default About
