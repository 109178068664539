import React from "react"
import { useNavigate } from "react-router-dom"
import data from "../../static/FAQ.json"
import Divider from "../../components/atoms/Divider/Divider"
import ScrollButton from "../../components/atoms/ScrollButton/ScrollButton"
import Collapsible from "../../components/molecules/Collapsible/Collapsible"
import SectionHeading from "../../components/molecules/SectionHeading/SectionHeading"
import { SectionBanner } from "../../components/organisms/SectionBanner/SectionBanner"
import "./FAQ.css"

function FAQ() {
  const navigate = useNavigate()
  const onClickContact = () => {
    window.scrollTo({
      top: 0,
    })
    navigate("/contact")
  }

  const questionsList = data.questions.map(
    ({ children, title }: any, index) => {
      return (
        <Collapsible
          key={index}
          children={children}
          title={title}
        ></Collapsible>
      )
    }
  )

  return (
    <div className="faq-page">
      <SectionHeading isDark titleEN="FAQ" titleJP="よくある質問" />
      <div className="section-heading-divider responsive-grid">
        <div className="grid-span-1"></div>
        <div className="grid-span-2">
          <div className="w-72">
            <Divider />
          </div>
        </div>
        <div className="grid-span-1-end"></div>
      </div>
      <div className="faq-section responsive-grid">
        <div className="grid-span-1"></div>
        <div className="faq-grid-wrapper grid-span-2">
          <div className="faq-question-table">{questionsList}</div>
        </div>
        <div className="grid-span-1-end"></div>
      </div>

      <div className="contact-section-heading">
        <SectionBanner
          bannerTitle="CONTACT US"
          animation="./assets/gif/ServiceGif/consulting.gif"
          title="私たちと新しいサービスを生み出しませんか？"
          buttonTitle="お問合せする"
          onButtonClick={onClickContact}
        ></SectionBanner>
      </div>
      <ScrollButton />
    </div>
  )
}

export default FAQ
