import React from "react"
import "./Divider.css"

interface DividerLayout {
  isDarkTheme?: boolean
  customBackground?: string
}

const Divider: React.FC<DividerLayout> = ({
  isDarkTheme = false,
  customBackground,
}) => {
  return (
    <div
      className={`divider ${
        isDarkTheme ? "divider-dark" : "divider-light"
      } ${customBackground}`}
    ></div>
  )
}

export default Divider
