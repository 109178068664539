import React from "react"
import { useNavigate } from "react-router"
import { ButtonMain } from "../../components/atoms/ButtonMain/ButtonMain"
import "./404.css"
import logo from "./logo.svg"

const Error404: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className="error-template">
      <div className="error-id">
        4
        <div className="error-logo">
          <object data={logo} width="64px" aria-label="logo"></object>
        </div>
        4
      </div>
      <h5 className="error-details tablet:text-p tablet:text-bold">
        お探しのページは見つかりませんでした。
      </h5>
      <p className="error-description tablet:text-caption tablet:mb-10">
        ページが移動または削除されたか、URLの入力間違いの可能性があります。
        <br />
        正しいURLをご指定いただくか、ホームに戻ってご覧になりたい情報をお探しください。
      </p>
      <ButtonMain
        isDarkTheme
        layout="decorated"
        title="ホームに戻る"
        onClick={() => navigate("/")}
      />
    </div>
  )
}

export default Error404
