import { useState } from "react"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import Divider from "../../atoms/Divider/Divider"
import { InputDropdown } from "../../atoms/InputDropdown/InputDropdown"
import { InputField } from "../../atoms/InputField/InputField"
import "./ContactForm.css"
import axios from "axios"
import { isNotEmpty } from "../../../utils/string"
import { gaEventTrack } from "../../../utils/gaEventTrack"

import gaEvents from "../../../static/gaEvents.json"

const ContactForm = () => {
  const contactOptions = [
    {
      id: 0,
      value: "サービスや事業・開発についてのご相談",
    },
    {
      id: 1,
      value: "弊社のサービス内容についてのご質問",
    },
    {
      id: 2,
      value: "パートナーシップ、協業について",
    },
    {
      id: 3,
      value: "採用、インターンについて",
    },
    {
      id: 4,
      value: "その他",
    },
  ]

  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  })

  const [listValue, setListValue] = useState<string>("")

  const isSubmittable =
    isNotEmpty(listValue) &&
    isNotEmpty(form.name) &&
    isNotEmpty(form.email) &&
    isNotEmpty(form.message)

  function handleChange(id: string, value: string) {
    setForm({
      ...form,
      [id]: value,
    })
  }

  async function handleSubmit() {
    gaEventTrack(
      gaEvents.home.categoryName,
      gaEvents.home.actions.submitContact,
      "送信"
    )

    const webhookUrl =
      "https://hooks.slack.com/services/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"

    const data = {
      text: `お問い合わせ種別：${listValue} \n お名前：${form.name} \n メールアドレス：${form.email} \n 内容：${form.message}`,
    }

    const res = await axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data) => {
          return data
        },
      ],
    })

    if (res.status === 200) {
      setListValue("")
      setForm({
        name: "",
        email: "",
        message: "",
      })
    } else {
      alert("エラーが発生しました。もう一度試して下さい")
    }
  }

  function handleClear() {
    gaEventTrack(
      gaEvents.home.categoryName,
      gaEvents.home.actions.clearContactForm,
      "クリア"
    )
    setForm({
      name: "",
      email: "",
      message: "",
    })
    setListValue("")
  }

  return (
    <div className="contact-form">
      <div className="section-title monotas-font text-orange-s100">CONTACT</div>
      <Divider isDarkTheme />
      <div className="section-content flex flex-col items-center">
        <div className="contact-main-form">
          <h3 className="contact-form-title tablet:text-h5">
            私たちと新しいサービスを
            <br />
            生み出しませんか？
          </h3>
          <InputDropdown
            isRequired
            label="お問合せ種別"
            placeholder="下記より選択して下さい"
            value={listValue}
            optionProp={contactOptions}
            onChange={(value) => setListValue(value)}
          />
          <InputField
            id="name"
            label="お名前"
            placeholder="お名前を入力して下さい"
            layout="default"
            isRequired
            value={form.name}
            handleChange={handleChange}
          />
          <InputField
            label="メールアドレス"
            placeholder="Emailアドレスを入力して下さい"
            layout="email"
            isRequired
            value={form.email}
            handleChange={handleChange}
          />
          <InputField
            id="message"
            label="お問合せ内容"
            placeholder="お問合せ内容を入力して下さい"
            layout="text-area"
            isRequired
            value={form.message}
            handleChange={handleChange}
          />
          <div className="button-row">
            <ButtonMain
              title="送信"
              layout="primary"
              hasIcon
              isDisabled={!isSubmittable}
              onClick={isSubmittable ? handleSubmit : undefined}
            />
            <ButtonMain
              title="クリア"
              layout="secondary"
              onClick={handleClear}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
