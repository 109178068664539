import "./FAQ.css"
import Collapsible from "../../molecules/Collapsible/Collapsible"
import { ButtonMain } from "../../atoms/ButtonMain/ButtonMain"
import data from "../../../static/FAQ.json"
import SectionHeading from "../../molecules/SectionHeading/SectionHeading"
import React from "react"

interface Props {
  faqRef: React.MutableRefObject<any>
  scrollTo: Function
}

const questionsList = data.questions
  .slice(0, 5)
  .map(({ children, title }: any, index) => {
    return (
      <Collapsible key={index} children={children} title={title}></Collapsible>
    )
  })

const FAQ: React.FC<Props> = ({ faqRef, scrollTo }) => {
  return (
    <section id="faq" className="faq" ref={faqRef}>
      <SectionHeading isDark titleEN="FAQ" titleJP="よくある質問" />
      <div className="faq-section responsive-grid">
        <div className="grid-span-1"></div>
        <div className="faq-grid-wrapper grid-span-2">
          <div className="faq-question-table">{questionsList}</div>
          <a href="#contact">
            <ButtonMain
              title="解決しない場合 お問い合わせへ"
              layout="decorated"
              isDarkTheme
              onClick={scrollTo}
            ></ButtonMain>
          </a>
        </div>
        <div className="grid-span-1-end"></div>
      </div>
    </section>
  )
}

export default FAQ
