import React from "react"
import ServiceCard from "../../molecules/ServiceCard/ServiceCard"
import "./ServicesOutline.css"

import data from "../../../static/serviceCardsData.json"
import gaEvents from "../../../static/gaEvents.json"

interface Props {
  servicesRef: React.MutableRefObject<any>
  cardRef: (index: number) => React.MutableRefObject<any>
  scrollTo: (
    googleAnalyticsCategory: string,
    googleAnalyticsAction: string,
    googleAnalyticsLabel: string,
    section: React.MutableRefObject<any>
  ) => void
}

const ServicesOutline: React.FC<Props> = ({
  servicesRef,
  cardRef,
  scrollTo,
}) => {
  const handleDetailsButtonClick = (index: number) => {
    let gaAction

    switch (index) {
      case 0:
        gaAction = gaEvents.home.actions.pushScratchBuild
        break
      case 1:
        gaAction = gaEvents.home.actions.pushRefactoring
        break
      case 2:
        gaAction = gaEvents.home.actions.pushPrototyping
        break
      case 3:
        gaAction = gaEvents.home.actions.pushConsulting
        break
      default:
        gaAction = gaEvents.home.actions.pushScratchBuild
    }

    scrollTo(gaEvents.home.categoryName, gaAction, "詳細へ", cardRef(index))
  }

  return (
    <section id="services" className="service-outline" ref={servicesRef}>
      <div className="title-section py-8 section-px">
        <p className="title monotas-font">OUR SOLUTIONS</p>
      </div>
      <div className="content-section">
        <div className="description responsive-grid">
          <div className="content-catchphrase p-small grid-span-1">
            Better System
            <br />
            Better Digital Experience
          </div>
          <h4 className="content-problems text-h4-5 grid-span-3 tablet:text-p-large">
            「開発をどう進めれば良いのかわからない」
            <br />
            「既存システムを改善したい」
            <br />
            「データの適切な扱い方がわからない」
            <br />
            「どのソフトウェアを使えば良いの？」
            <br />
            <br />
            そんなあなたに、モノタスは解決方法を提供します
          </h4>
        </div>
        <div className="services-list responsive-grid">
          {data.cards.map(({ animation, title, content }: any, index) => (
            <ServiceCard
              key={index}
              animation={animation}
              title={title}
              content={content}
              onDetailsButtonClick={() => handleDetailsButtonClick(index)}
            ></ServiceCard>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ServicesOutline
