import React from "react"
import ContactForm from "../../components/organisms/ContactForm/ContactForm"
import "./Contact.css"

function ContactUs() {
  return (
    <section className="contact-page">
      <ContactForm></ContactForm>
    </section>
  )
}

export default ContactUs
