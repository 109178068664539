import React, { ReactElement } from "react"
import Divider from "../../atoms/Divider/Divider"
import "./TableItem.css"

export interface IProps {
  title: string
  isJpTitle?: boolean
  content?: string
  customContent?: ReactElement
}

const TableItem: React.FC<IProps> = ({
  title,
  content,
  isJpTitle,
  customContent,
}) => {
  return (
    <div className="table-item">
      <div className="table-item-content responsive-grid">
        <div className="grid-span-1">
          <h5
            className={`title tablet:text-p-large ${
              !isJpTitle ? "font-normal font-brand" : null
            }`}
          >
            {title}
          </h5>
        </div>
        {content ? (
          <div className="content grid-span-2 tablet:text-p-small">
            {content}
          </div>
        ) : (
          <div className="content grid-span-2">{customContent}</div>
        )}
        <div className="grid-span-1-end"></div>
      </div>
      <Divider />
    </div>
  )
}

export default TableItem
