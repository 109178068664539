import useScroll from "../../../hooks/useScroll"
import { gaEventTrack } from "../../../utils/gaEventTrack"
import { DoubleArrow } from "../Icons/DoubleArrow/DoubleArrow"
import "./ScrollButton.css"

import gaEvents from "../../../static/gaEvents.json"

const ScrollButton = () => {
  const isScrolling = useScroll()

  const scrollToTop = () => {
    gaEventTrack(
      gaEvents.others.categoryName,
      gaEvents.others.actions.scrollToTop,
      ""
    )
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <button
      className={`scroll-button ${isScrolling ? "visible" : ""}`}
      onClick={scrollToTop}
    >
      <div className="arrow-icon">
        <DoubleArrow />
      </div>
      <caption className="font-bold">トップへ</caption>
    </button>
  )
}

export default ScrollButton
