import { DoubleArrow } from "../Icons/DoubleArrow/DoubleArrow"
import "./ButtonMain.css"

type Primary = {
  layout: "primary"
  isDisabled?: boolean
  title: string
  hasIcon?: boolean
  className?: string
  onClick?: Function
}

type Secondary = {
  layout: "secondary"
  isDisabled?: boolean
  title: string
  hasIcon?: boolean
  className?: string
  onClick?: Function
}

type Decorated = {
  layout: "decorated"
  isDisabled?: boolean
  isDarkTheme: boolean
  title: string
  hasIcon?: boolean
  className?: string
  onClick?: Function
  customBackground?: string
}

export type ButtonType = Primary | Secondary | Decorated

export function ButtonMain(props: ButtonType) {
  return (
    <button
      className={`button-main button-${props.layout}`}
      disabled={props.isDisabled}
      onClick={() => props.onClick?.()}
    >
      {props.layout === "decorated" ? (
        <div className={`decorated ${!props.isDarkTheme ? "light" : ""}`}>
          <div className={`decorated-title ${props.customBackground}`}>
            <div className="inner-circle"></div>
            <div className="button-label p-small tablet:text-caption">
              {props.title}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="button-label p-small flex items-center">
            {props.title}
            {props.hasIcon === true ? (
              <div className="arrow-icon">
                <DoubleArrow size="8px" />
              </div>
            ) : null}
          </div>
        </>
      )}
    </button>
  )
}
